import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';

import {store} from './store/configureStore';
import {LanguageProvider} from "./context/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
      <Provider store={store}>
          <LanguageProvider>
              <App />
          </LanguageProvider>
      </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/play/firebase-messaging-sw.js?11', { scope: '/play/'} )
        .then(function(registration) {
            console.log('sw registered with scope:', registration);
        }).catch(function(err) {
        console.log('Service Worker registration failed:', err);
    });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
