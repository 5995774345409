import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useGetNotificationsQuery, useGetUserQuery, useTestSendMutation} from "../store/api/api-requests";



function TableHeader(props) {
    return null;
}

const Notifications = ({user_id, xsolla_id}) => {
    const [isOpen, setIsOpen] = useState(false);


    const getBrowserName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.includes("Firefox")) {
            return "Firefox";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            return "Opera";
        } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Chrome";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Safari";
        } else if (userAgent.includes("Edg")) {
            return "Edge";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            return "Internet Explorer";
        } else {
            return "unknown";
        }
    }

    const notifications = useGetNotificationsQuery({
        xsolla_id:xsolla_id, generated_id: user_id, browser: getBrowserName()
    },{        pollingInterval: 15000 });

    console.log('notifications')
    console.log(notifications)
    console.log(notifications?.data?.data.filter(item => item.send == false))

    const data = notifications.data == undefined ? {data:[]} : notifications.data


    const [testSend, testSendResponse] = useTestSendMutation()

    const handleSend = (eventId) => {
        testSend({
            user_id:user_id,
            browser:getBrowserName(),
            event_id:eventId
        })
    };

    const formatDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleString();
    };

    return (
        <div className="p-4">
            <Button onClick={() => {setIsOpen(true)}}>Notifications queue</Button>

            <Dialog fullWidth={true} maxWidth={'xl'} open={isOpen} onOpenChange={setIsOpen}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setIsOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Заголовок</TableHead>
                                <TableHead>Содержание</TableHead>
                                <TableHead>Дата</TableHead>
                                <TableHead>ID события</TableHead>
                                <TableHead>Действия</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.data.filter(item => item.send == false).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.body}</TableCell>
                                    <TableCell>{formatDate(item.date)}</TableCell>
                                    <TableCell>{item.eventId}</TableCell>
                                    <TableCell>{item.send?'отправлен':'не отправлен'}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => handleSend(item.eventId)}
                                            variant="secondary"
                                            size="sm"
                                        >
                                            Отправить
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Notifications;