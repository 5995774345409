import React, {useState, useEffect, useRef} from 'react';
import logo from './logo.svg';
import './App.css';
import './flags.css';
import {
    Button, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, MenuItem, Select, TextField,
} from "@mui/material";

import {Header} from "./components/header";
import UnityWrapper from "./unity";
import './utils/common'

import {
    useAddRewardMutation,
    useCancelNotificationMutation,
    useCheckTokenMutation, useDeleteInfoMutation, useGetUserQuery,
    useSaveTokenMutation,
    useSendMsgMutation, useSetOldMutation, useUpdateUserMutation
} from "./store/api/api-requests";

import {getMessaging, getToken} from "firebase/messaging";

import {getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import {useLiveQuery} from "dexie-react-hooks";
import {initializeApp} from "firebase/app";
import {useDispatch, useSelector} from "react-redux";
import {LanguageProvider, useLanguage} from "./context/LanguageContext";
import translations from "./utils/translations";

import AppStoreImg from "./img/app-store-badge.png"
import AmazonStoreImg from "./img/amazon-store-badge.png"
import GoogleStoreImg from "./img/google-play-badge.png"
import Notifications from "./components/notifications";
import * as Sentry from "@sentry/react";


const firebaseConfig = {
    apiKey: "AIzaSyA4SwWpW-GwUltuhZQeIYSMk1nsN2wy9Pk",
    authDomain: "cm-test-853b4.firebaseapp.com",
    projectId: "cm-test-853b4",
    storageBucket: "cm-test-853b4.appspot.com",
    messagingSenderId: "1064677070576",
    appId: "1:1064677070576:web:29c6c1ebff53a123769435",
    measurementId: "G-G0TD9K6JK7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = 'Notification' in window ? getMessaging() : null;


function App() {
    const urlParams = new URLSearchParams(window.location.search);

    const [buildNumber, setBuildNumber] = useState(urlParams.get('build') || localStorage.getItem('build') || false);
    const [unityVersion, setUnityVersion] = useState(null);
    const jwtData = window.getParsedXsollaJwt()

    const initialBuild = urlParams.get('build') || localStorage.getItem('build') || false;
    const initialCheats = urlParams.get('cheats') || localStorage.getItem('cheats') || '0'; // Defaults to '0' if nothing is set

    const [isPwaInstalled, setIsPwaInstalled] = useState(true)
    const [promptStarted, setPromptStarted] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [updateUser, updateUserResponse] = useUpdateUserMutation()

    const dispatch = useDispatch()
    const userState = useSelector((state) => state.data.user)
    const { language, changeLanguage, getT } = useLanguage();


    const [state, setState] = useState({
        language: 'English',
        isCookiePolicyOpen: false,
    });

    const [tempToken, setTempToken] = useState(false)
    const [user, setUser] = useState(false)
    const [tempUser, setTempUser] = useState(false)
    const [userLoaded, setUserLoaded] = useState(false)

    console.log("userInfo")
    const userInfo = useGetUserQuery({
        xsolla_id:jwtData!=null&&jwtData.sub!=undefined ? jwtData.sub:null,
        generated_id: window.generatedUserUuid,
        pwa_installed: !(localStorage.getItem("isPwaInstall")=='false'),
        notification_enabled: Notification?.permission == 'granted',
        email: jwtData!=null&&jwtData.email!=undefined ? jwtData.email : null
    }, {skip: window.generatedUserUuid == null || window.generatedUserUuid == false|| window.generatedUserUuid == 'null' });

    console.log('---userInfo---')
    console.log(userInfo)

    const [setOld, setOldResponse] = useSetOldMutation()
    const [deleteInfo, deleteInfoResponse] = useDeleteInfoMutation()
    const [addReward, addRewardResponse] = useAddRewardMutation()
    const [saveToken, saveTokenResponse] = useSaveTokenMutation()
    const [checkToken, getCheckToken] = useCheckTokenMutation()
    const [sendMsg, sendMsgResponse] = useSendMsgMutation()
    const [cancelNotifications, cancelNotificationsResponse] = useCancelNotificationMutation()
    const [token, setToken] = useState(localStorage.getItem('token')==undefined?'':localStorage.getItem('token'))


    const [openDialog, setOpenDialog] = useState({
        cookie: false,
        notification: false
    });
    const [confirmN, setConfirmN] = useState(false);

    const [play, setPlay] = useState(false)


    const buildType = urlParams.get('buildType') == 'normal' ? 'normal' : 'downscale'

    const [iB, setiB] = useState(initialBuild);
    const [iCheat, setiCheat] = useState(initialCheats);

    const lastSymbol = parseInt(window.generatedUserUuid[window.generatedUserUuid.length - 1], 10);
    const abMode = isNaN(lastSymbol) || lastSymbol >= 5 ? 'b' : 'a';
    const [mode, setMode] = useState(urlParams.get('mode') || abMode);


    const handleCookiePolicy = (value) => {
        dispatch({ type: 'APPROVE_COOKIE', payload: value })
        setOpenDialog({...openDialog, cookie: false})
        window.localStorage.setItem('cookie_policy', value)
    };


    const handleDialogClose = () => {
        setDialogOpen(false);
    };


    const installHandler =  () => {
        console.log('pwaWindowOpen');
        if(promptStarted) {
            setDialogOpen(true);
            return false;
        }
        setPromptStarted(true);

        window.postAnalyticsEvent("pwaWindowOpen", window.userID, "", false);

        if (window.installPromptEvent === undefined) {
            console.log("installPromptEvent undefined")
            console.log(window.installPromptEvent)
            return;
        }
        const result = window.installPromptEvent.prompt();
        console.log('result');
        console.log(result);
        window.installPromptEvent.userChoice.then((choiceResult) => {


            if (choiceResult.outcome === 'accepted') {
                console.log('accepted PWA');
                window.isPwaInstalled = true
                localStorage.setItem("isPwaInstall", "true")
                setIsPwaInstalled(true)
                window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=1", false);

            } else {
                localStorage.setItem("isPwaInstall", "false")
                console.log('declined PWA');

                window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=0", false);

            }

            setPromptStarted(false);

            window.installPromptEvent = null;
        });

    }


    useEffect(() => {
        const checkIsPwaInstalled = () => {
            if ('Notification' in window) {

                const currentPermission = Notification.permission;
                const checkPwaInstalled = localStorage.getItem('isPwaInstall');

                if (checkPwaInstalled == 'true' && isPwaInstalled == false) {
                    // Отправляем аналитическое событие
                    window.postAnalyticsEvent("pwaDeleted", window.userID, "", false);
                }
                if(checkPwaInstalled == 'false' && isPwaInstalled) {
                    //window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=1", false);
                }
            }
        };

        // Устанавливаем интервал для регулярной проверки
        const interval = setInterval(checkIsPwaInstalled, 100000); // проверяем каждые 10 секунд

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        let isStandalone = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
        if(isStandalone) {
            setIsPwaInstalled(true)
            localStorage.setItem("isPwaInstall", "true")

            updateUser({
                generated_id: window.generatedUserUuid,
                pwa_installed: true,
                notification_enabled: Notification?.permission == 'granted'
            })
        }

        const handleBeforeInstallPrompt = (event) => {
            console.log('event beforeinstallprompt triggered')
            console.log(event)
            event.preventDefault();
            window.installPromptEvent = event;
            window.installPrompt = true
            window.isPwaInstalled = false
            setIsPwaInstalled(false)
            localStorage.setItem("isPwaInstall", "false")

            updateUser({
                generated_id: window.generatedUserUuid,
                pwa_installed: !(localStorage.getItem("isPwaInstall")=='false'),
                notification_enabled: Notification?.permission == 'granted'
            })
        }
        if ('onbeforeinstallprompt' in window) {
            window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        } else {
            console.log('beforeinstallprompt not supported on this browser.');
        }

        return () => {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("pwaInstallAccepted", function() {
            setIsPwaInstalled(true)
        });

    })

    useEffect(() => {
        const fetchBuildNumber = async () => {
            const url = process.env.REACT_APP_VERSION_URL+'/buildNumber.js?t='+Date.now()

            try {
                const response = await fetch(url);
                const text = await response.text();
                const match = text.match(/var unityBuildNumber\s*=\s*(\d+)/);

                if (match && match[1]) {
                    setBuildNumber(Number(match[1]));
                    window.unityBuildNumber = Number(match[1])
                } else {
                    console.error('unityBuildNumber not found');

                    Sentry.captureMessage(
                        `unityBuildNumber not found \n${url}`,
                        "Error"
                    )
                }
            } catch (error) {
                console.error('Error fetching build number:', error);
                //default
                setBuildNumber(6274);
                window.unityBuildNumber = 6274


                Sentry.captureMessage(
                    `unityBuildNumber not found, use default \n${url}`,
                    "Error"
                )
            }
        };
        const fetchVersion = async () => {
            const url = process.env.REACT_APP_VERSION_URL+'/version.js?t='+Date.now()

            try {
                const response = await fetch(url);
                const text = await response.text();
                const match = text.match(/var unityVersion\s*=\s*"([^"]+)"/);

                if (match && match[1]) {
                    setUnityVersion((match[1]));
                    window.unityVersion = (match[1])
                } else {
                    console.error('unityVersion not found');

                    Sentry.captureMessage(
                        `unityVersion not found, use default \n${url}`,
                        "Error"
                    )
                }
            } catch (error) {
                console.error('Error fetching unityVersion:', error);
                setUnityVersion('80.0.0');
                window.unityVersion = '80.0.0'


                Sentry.captureMessage(
                    `unityVersion not found, use default \n${url}`,
                    "Error"
                )
            }
        };
        if(!buildNumber)
            fetchBuildNumber();
        if(!unityVersion)
            fetchVersion();


    }, []);

    useEffect(() => {
        if(buildNumber&&unityVersion) {
            sendBuildNumber();
        }
    }, [unityVersion, buildNumber])


    useEffect(() => {
        window.postAnalyticsEvent("startedAbtestWebGL", window.generatedUserUuid, "#is#mode="+mode, false);

    },[])


    const handleCancelAllScheduledNotifications = (event) => {
        cancelNotifications({
            user_id: window.gameParams.user_id
        })
    };

    const handleUnityChangeLanguage = (event) => {
        console.log('handleUnityChangeLanguage');
        console.log(event.detail);
        changeLanguage(event.detail.value)
    };

    const handleNotifEvent = async (event) => {
        console.log('handleNotifEvent ', event);
        sendMsg({
            title: event.detail.title,
            body: event.detail.description,
            date: event.detail.time,
            event_id: event.detail.eventID,
            user_id: window.gameParams.user_id,
            browser: getBrowserName(),
            timezone: new Date().getTimezoneOffset()
        })
    };

    useEffect(() => {
        debugger

        window.addEventListener('cancelAllScheduledNotifications', handleCancelAllScheduledNotifications);
        window.addEventListener('changeLocalization', handleUnityChangeLanguage);
        window.addEventListener('requestNotificationPermission', showNotificationPermissionDialog);//askNotificationPermission
        window.addEventListener('notifEvent', handleNotifEvent);

        return () => {

            window.removeEventListener('cancelAllScheduledNotifications', handleCancelAllScheduledNotifications);
            window.removeEventListener('changeLocalization', handleUnityChangeLanguage);
            window.removeEventListener('requestNotificationPermission', showNotificationPermissionDialog);
            window.removeEventListener('notifEvent', handleNotifEvent);

        };
    }, []);



    window.isTokenFetching = false;

    const isMobile = ((navigator.userAgent.match("Mac") || navigator.userAgent.match("Linux")) &&
        navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && navigator.maxTouchPoints !== 256)

    const getBrowserName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.includes("Firefox")) {
            return "Firefox";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            return "Opera";
        } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Chrome";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Safari";
        } else if (userAgent.includes("Edg")) {
            return "Edge";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            return "Internet Explorer";
        } else {
            return "unknown";
        }
    }

    const requestForToken = async () => {
        if (window.isTokenFetching) return false;
        window.isTokenFetching = true

        console.log("requestForToken>> start")
        // const storedToken = localStorage.getItem('token');
        // if (storedToken) {
        //     setToken(storedToken);
        //     return storedToken;
        // }

        try {
            console.log("requestForToken>> try")

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/play/firebase-messaging-sw.js?11', { scope: '/play/'})
                    .then(async function (registration) {
                        const currentToken = await getToken(messaging, {
                            vapidKey: 'BLX3LM5yo1SXgSuVa3BDruQlfqXTLQSENxDD4HVzzLsZDeGmK_6DwjP2nVLpR5EskX8z2i3v7NnaQaDhe16nYiQ',
                            serviceWorkerRegistration: registration
                        })
                        console.log('requestForToken>> ');
                        console.log(currentToken);

                        if (currentToken) {
                            console.log('current token for client: ', currentToken);
                            setToken(currentToken)
                            setTempToken(currentToken)
                            saveToken({
                                token: currentToken,
                                user_id: window.gameParams.user_id,
                                browser: getBrowserName()
                            })
                            localStorage.setItem('token', currentToken)
                            window.isTokenFetching = false
                            return currentToken
                        } else {
                            console.log('requestForToken>> No registration token available. Request permission to generate one.');
                            return false;
                        }

                    }).catch(function (err) {
                    console.log('requestForToken>> Service Worker registration failed:', err);
                })
            } else {
                console.log("requestForToken>> error")

            }

        } catch (err) {
            console.log('An error occurred while retrieving token. ', err);
            throw err; // Rethrow the error if needed
        }
    };


    const checkNotificationPromise = () => {
        let result = false;
        try {
            console.log("ok perm prom")
            Notification.requestPermission().then((f) => {
                console.log('f: '+f)

                result = true;
            }).catch((e) => {
                console.log('e: ' +e)
                result = false
            });
        } catch(e) {
            console.log("nook perm prom")
            result = false
            return result;
        }

        return result;
    }

    const handlePermissionPromise = (permission) => {

        if (permission === 'granted') {
            console.log('start token request')
            window.postAnalyticsEvent("notificationEnable", window.userID, "", false);

            requestForToken().then()
        } else {
            window.postAnalyticsEvent("notificationDisable", window.userID, "", false);
        }

    }
    useEffect(() => {
        if(localStorage.getItem("registration") == "init" && jwtData != null && jwtData.hasOwnProperty('sub')) {
            localStorage.setItem("registration", "reward")
        }
    })

    const lastPermissionRef = useRef(localStorage.getItem('lastNotificationPermission') == null ? Notification.permission : localStorage.getItem('lastNotificationPermission'));

    useEffect(() => {
        const checkNotificationPermission = () => {
            try {
                console.log('checkNotificationPermission')

                const currentPermission = Notification.permission;
                const lastPermission = lastPermissionRef.current

                console.log(currentPermission)
                console.log(lastPermission)

                // Сохраняем текущее разрешение как последнее проверенное
                localStorage.setItem('lastNotificationPermission', currentPermission);
                lastPermissionRef.current = currentPermission.toString()

                // Если разрешение изменилось с 'granted' на 'denied'
                if ((lastPermission === 'granted' || lastPermission == 'default') && (currentPermission === 'denied' || currentPermission === 'default')) {

                    // Отправляем аналитическое событие
                    window.postAnalyticsEvent("notificationDisable", window.userID, "", false);
                }
                if((lastPermission == 'denied' && currentPermission == 'granted') ||
                    (lastPermission == 'default' && currentPermission == 'granted') ) {
                    console.log('start token request 2')
                    window.postAnalyticsEvent("notificationEnable", window.userID, "", false);

                    requestForToken().then()
                }
            } catch (e) {

            }

        };

        // Устанавливаем интервал для регулярной проверки
        const interval = setInterval(checkNotificationPermission, 5000); // проверяем каждые 10 секунд

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, []);


    const handlePermission = (permission) => {
        if (!('permission' in Notification)) {
            Notification.permission = permission;
        }
    }

    const showNotificationPermissionDialog = () => {
        console.log('showNotificationPermissionDialog')
        if(Notification.permission == 'default') askNotificationPermission()
        else  setOpenDialog({...openDialog, notification: true}); // Open dialog if permission is default
    }

    const askNotificationPermission = () => {
        localStorage.setItem('lastNotificationPermission', Notification.permission);

        if(Notification.permission == 'granted') return false;

        if (!("Notification" in window)) {
            console.error("This browser does not support notifications.");
        } else {
            console.log("start request N")
            if (Promise) {
                Notification.requestPermission().then((permission) => {
                    console.log(('request perm PROMISE'))

                    handlePermissionPromise(permission)
                }).catch((e) => {
                    console.log(('request perm PROMISE err'))

                });
            } else {
                Notification.requestPermission(function(permission) {
                    console.log(('request perm'))
                    handlePermissionPromise(permission)
                });
            }
        }
    }

    const requestPermissionsPopup = () => {
        if (!("Notification" in window)) {
            console.error("This browser does not support notifications.");
            return;
        }

        // Check current permission status
        const permission = Notification.permission;
        if (permission === "default") {
            // Permission not granted or denied yet, show dialog to ask for permission
            setOpenDialog({...openDialog, notification: true});
        }

    }

    useEffect(() => {
        console.log("use eff confirm N")
        console.log(confirmN)
        if(confirmN == true) {
            //askNotificationPermission()
            //requestPermissionsPopup()
        }
    }, [confirmN])



    const sendNotification = () => {
        if (Notification.permission === "granted") {
            navigator.serviceWorker.getRegistration().then(function(reg) {
                reg.showNotification('Hello')
            }).catch((e) => {

            });
        }
    }


    const handleBuildChange = (event) => {
        const newBuild = event.target.value;
        localStorage.setItem('build', newBuild);  // Update local storage
        setiB(newBuild);  // Update state
        sendBuildNumber();// Update state
    };

    const handleCheatChange = (event) => {
        const newCheat = event.target.value;
        localStorage.setItem('cheats', newCheat);  // Update local storage
        setiCheat(newCheat);  // Update state
    };

    const sendBuildNumber = () => {
        setUserProperties(analytics, {
            build_number: buildNumber,
            version_number: unityVersion

        });
    }

    useEffect(() => {
        let userId = window.initApp()
        setTempUser(userId)
    },[])
    useEffect(() => {
        if(userInfo.isLoading == false && userInfo.data!=undefined) {
            const id = userInfo.data.new_user ? userInfo.data.user_id : userInfo.data.xsolla_id
            window.userID = id
            window.gameParams.user_id = id
            setUser(id)
            if(userInfo.data.reward == true) {
                debugger
                localStorage.setItem("registration","complete")
            }

            window.postAnalyticsEvent("pageLoadComplete", window.gameParams.user_id, "", false);

        }
        if(userInfo.status == "rejected") {
            window.userID = window.generatedUserUuid
            window.gameParams.user_id = window.generatedUserUuid
            setUser(window.generatedUserUuid)

            Sentry.captureMessage(
                `get_user error \n${userInfo}`,
                "Error"
            )
        }
    },[userInfo.isLoading])


    useEffect(() => {

        //if((jwtData == null || !jwtData.hasOwnProperty("sub"))) {
        if (buildNumber && unityVersion && userInfo.data!=undefined &&
            userInfo.isLoading==false && userInfo.data.xsolla_id.length<10) {
            if(mode!='b'&&(jwtData==null|| !jwtData.hasOwnProperty("sub"))) {
                window.showXsollaLogin()
            }
        }

    }, [unityVersion, buildNumber, userInfo ])


    if (!buildNumber || !unityVersion || userInfo.isLoading || user == false) {
        return <CircularProgress color="inherit"/>;
    }

    return (
        <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>

        {(!userInfo.isError&&!userInfo.isLoading)&&<div>
                {userInfo.data.new_user?"New":"Old"} | XsollaId: {userInfo.data.xsolla_id} | StateId: {userInfo.data.user_id}
                <Button onClick={() => {
                    setOld({xsolla_id: userInfo.data.xsolla_id, new_user: false})

                }}>Mark XSOLLA as Old</Button>
                |
                <Button onClick={() => {
                    setOld({xsolla_id: userInfo.data.xsolla_id, new_user: true})

                }}>Mark XSOLLA as NEW</Button>
                |
                <Button onClick={() => {
                    deleteInfo({user_id: userInfo.data.user_id})
                }}>Delete info</Button>

                <Notifications user_id={userInfo.data.user_id} xsolla_id={userInfo.data.xsolla_id} />

            </div>}

            <div className={`App ${isMobile ? 'mobile-bg' : 'cm-bg'}`}>
                <Header
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    handleDialogClose={handleDialogClose}
                    isPwaInstalled={isPwaInstalled}
                    installHandler={installHandler}
                    jwtData={jwtData}
                />


                {!isMobile ? <>
                    {(play||(user!=false&&user!=undefined&&userInfo.data!=undefined&&userInfo.data.xsolla_id.length>10&&mode=='a')||mode=='b') ? <UnityWrapper addReward={addReward} userInfo={userInfo} buildNumber={buildNumber} jwtData={jwtData} />: <div className={"startPlay"}>
                        <div className="playBtn-wrapper">
                            <div className={"playBtn"} onClick={() => {
                                const jwtData = window.getParsedXsollaJwt()
                                if(jwtData == null || !jwtData.hasOwnProperty("email")) {
                                    //window.showXsollaLogin()
                                } else {
                                    setPlay(true)}
                            }

                            }></div>
                        </div>
                    </div>}
                </>:<div id="blocker-wrapper"><div id="blocker">
                    <div id="blocker-label">
                        <div>
                            {getT('game_start_error')}

                        </div>
                    </div>
                    <div id="store-links-container">
                        <a href="https://apps.apple.com/us/app/clockmaker-match3-puzzle/id929195587" className="store-link">
                            <img src={AppStoreImg}
                                 className="store-link-image" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.belkatechnologies.clockmaker&amp;hl=en"
                           className="store-link">
                            <img src={GoogleStoreImg}
                                 className="store-link-image" />
                        </a>
                        <a href="https://www.amazon.com/Samfinaco-Limited-Clockmaker-Amazing-Match/dp/B00YXZ4L2Q/ref=sr_1_1?dchild=1&amp;keywords=clockmaker&amp;qid=1631532787&amp;sr=8-1"
                           className="store-link">
                            <img src={AmazonStoreImg}
                                 className="store-link-image" />
                        </a>
                    </div>
                </div></div>
                }
            </div>



            <footer style={{ }}>
                <div>
                    © Belka Games, 2024<br />
                    Samfinaco LLC<br />
                    1100 Page MillRoad Palo Alto, CA 94304
                </div>
                <div>
                    <Button
                        onClick={() => {
                            window.postAnalyticsEvent("supportButton", window.userID, "", false);
                        }}
                        href={"https://support.belka-games.com/hc/en-us/categories/360002081597-Clockmaker"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("support")}</Button>
                    <Button href={"https://belka-games.com/"+language.toLowerCase()+"/privacy"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("privacy")}</Button>
                    <Button href={"https://belka-games.com/"+language.toLowerCase()+"/terms-of-service"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("terms")}</Button>
                    {buildType!='normal'&&<Link href={"/play/?buildType=normal"} style={{color:"#fff"}} sx={{m:1}}>Open HD Quality</Link>}

                </div>
            </footer>
            <Dialog
                open={openDialog.notification}
                onClose={() => {
                    setOpenDialog({...openDialog, notification: false})

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {getT("show_notification_title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div dangerouslySetInnerHTML={{__html:getT("show_notification_description")}}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog({...openDialog, notification: false})}>{getT("no_close")}</Button>
                    <Button  target="_blank" href={getT('show_instructions_link')} onClick={() => { setOpenDialog({...openDialog, notification: false}); }} autoFocus>
                        {getT("show_instructions")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Sentry.ErrorBoundary>
    );
}

export default App;
