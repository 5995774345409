import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            return headers
        }
    }),
    endpoints: () => ({})
})

export default api
